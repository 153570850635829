@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --dark-blue: #17182c;
  --dark-blue-bg: #16182e;
  --dark-blue-accent: #a0a3c4;
  --dark-blue-hover: rgba(128, 138, 255, 0.0784313725490196);
  --dark-blue-border: hsla(0, 0%, 100%, 0.1607843137254902);
  --dark-blue-active: rgba(129, 140, 239, 0.2);
  --avax-red: #e84143;
  --arbitrum-blue: #28a0f0;
  --border-radius-sm: 4px;
  --short-active: #dd463a;
  --long-active: #3ee028;
  --background-primary: #000a00;
  --background-secondary: rgba(0, 9, 0, 0.2);
  --background-navigation: linear-gradient(
    83.12deg,
    rgba(9, 130, 0, 0.5) -208.54%,
    rgba(9, 130, 0, 0) 159.09%
  );
  --background-gradient: linear-gradient(
      83.12deg,
      rgba(9, 130, 0, 0.6) -208.54%,
      rgba(9, 130, 0, 0) 159.09%
    ),
    rgba(0, 10, 0, 0.9);
  --background-gradient-solid: linear-gradient(
      83.12deg,
      #098200 -208.54%,
      rgba(9, 130, 0, 0) 159.09%
    ),
    #000a00;
  --border-navigation: #098200;
  --action-active: #098200;
  --action-inactive: #093806;
  --action-hover: #003000;
  --action-stroke: #003000;
  --action-shadow: 0 0 4px 6px rgba(9, 130, 0, 0.1);
  --cell-gradient: linear-gradient(180deg, rgba(5, 67, 1, 0.2), rgba(5, 67, 1, 0) 65.01%);
  --cell-hover: #03100e;
  --cell-stroke: #003000;
  --cell-highlight: #098200;
  --cell-unavailable: rgba(49, 87, 136, 0.05);
  --cell-unavailable-stroke: rgba(49, 87, 136, 0.2);
  --tooltip-fill: #03100e;
  --tooltip-shadow: 0px 0px 7px rgba(39, 62, 89, 0.53);
  --text-primary: #fff;
  --text-secondary: #8b968c;
  --text-hover: #8b968c;
  --text-navigation: #fff;
  --danger: #191203;
  --danger-active: #ff5621;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

body {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;

  --background-primary: #010;

  --action-stroke: #003000;
  --action-fill: #001700;

  --text-secondary: #8b968c;

  --short-active: #dd463a;
  --long-active: #3ee028;
  overflow-x: hidden;
  overflow-y: overlay;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--cell-highlight);
  border: 1px solid transparent;
}

.recharts-wrapper {
  font-size: 0.8rem;
  font-family: 'Inter', sans-serif;
}

.recharts-legend-item-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.App {
  color: #000;
  background: #f6f9ff;
  line-height: 1.15;
  padding: 1rem;
  padding-top: 0;
  width: calc(100% - 280px);
  margin-left: 280px;
  transition: all 0.3s ease;
  padding-top: 60px;
}
.App.full-width {
  margin-left: 0;
  width: 100%;
}

.App.dark {
  background: #001100;
  color: #fff;
  line-height: 1.15;
  box-sizing: border-box;
  min-height: 100vh;
}

h3,
h2 {
  color: #444;
  margin: 1rem 0 1.5rem;
}

.App.dark h3,
.App.dark h2 {
  color: white;
}

h3 {
  margin: 1rem 0;
  font-weight: normal;
  font-size: 1.2rem;
}

h2 {
  font-size: 1.6rem;
  font-weight: normal;
}

label {
  cursor: pointer;
}

input + label,
label + input {
  margin-left: 0.5rem;
}

.chart-subtitle,
.stats {
  margin: 1.5rem 0 1rem;
}

.chart-description {
  margin: 1rem 0 0;
  max-width: 600px;
}

.App.dark .chart-description {
  color: var(--text-secondary);
}

.chart-description p:first-child {
  margin-top: 0;
}

.chart-description p:last-child {
  margin-bottom: 0;
}

.chart-description ul {
  margin: 0;
  padding-left: 20px;
}

.chart-description li {
  margin-top: 5px;
}

.chart-grid {
  color: var(--action-fill);
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.chart-sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0px;
  top: 0px;
  background: var(--background-primary);
  width: 270px;
  height: 100vh;
  z-index: 999;
  color: var(--action-fill);
  box-shadow: rgb(9 130 0) 1px -6px 32px -12px;
  padding: 12px 24px 24px;
  transition: transform 0.5s ease;
  transform: translateX(0px);
  min-height: 650px;
  z-index: 50;
}
.chart-sidebar.hidden {
  transform: translateX(-270px);
}
.hide-sidebar-btn {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -10px;
  width: 30px;
  height: 60px;
  border-radius: 7px;
  border: 0px;
  background-color: var(--background-primary);
  cursor: pointer;
}
.hide-sidebar-btn > img {
  width: 10px;
  transition: transform 0.5s ease 0s;
  transform: rotate(0deg) translateX(3px);
}
.chart-sidebar.hidden .hide-sidebar-btn > img {
  transform: rotate(180deg) translateX(-5px);
}
.sidebar-spacer {
  height: 66px;
}
.chart-grid-subtitle {
  grid-column-start: 1;
  grid-column-end: 9;
  margin-bottom: 0;
  margin-top: 2rem;
}

.total-stat-value {
  font-size: 1.5rem;
  overflow: hidden;
  white-space: pre-wrap;
  font-weight: 600;
}

.total-stat-delta {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: block;
  font-weight: 400;
}

.total-stat-delta.plus {
  color: var(--long-active);
}
.App.light .total-stat-delta.plus {
  color: rgb(34 199 97);
}
.total-stat-delta.minus {
  color: red;
}

.App .chart-cell {
  background: var(--action-fill);
  border: none;
  color: white;
  transition: background 0.3s ease;
  border: 1px solid transparent;
  transition: border 0.5s ease;
}

.App .chart-cell:not(.stats):hover {
  border: 1px solid var(--cell-highlight);
}

.App .chart-cell.stats {
  border-radius: 0;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--cell-stroke);
  background-color: transparent;
  padding: 16px 0;
}
.App .chart-cell.stats:last-of-type {
  border-bottom: 0;
}

.chart-cell {
  margin: 0;
  border-radius: 4px;
  border: 1px solid #e6e6f7;
  background: white;
  position: relative;
  padding: 1rem;
  grid-column-start: span 5;
}

.chart-cell.stats {
  min-height: 60px;
  grid-column-start: span 2;
}

.total-stat-label {
  color: var(--text-secondary);
  font-size: 12px;
  margin-bottom: 4px;
}
.total-stat-delta {
  font-size: 12px;
}
.chart-cell.experiment {
  border-color: #fbb;
}
.chart-cell.experiment:after {
  color: #f55;
  top: 1rem;
  right: 1rem;
  position: absolute;
  content: '(experimental)';
}

.chart-cell h3 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  letter-spacing: 0px;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  font-weight: 600;
  flex-grow: 1;
}

.chart-cell a.csv-link {
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 8px;
  display: inline-flex;
  vertical-align: middle;
  padding: 4px 6px;
  border-radius: 6px;
  border: 1px solid var(--cell-highlight);
  color: var(--cell-highlight) !important;
  transition: all 0.3s ease;
}
.chart-cell a.csv-link:hover {
  color: white !important;
  background-color: var(--cell-highlight) !important;
}

.chart-header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 16px;
  min-height: 40px;
}

.chart-controls {
  display: flex;
  align-items: center;
}

.chart-control-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 8px;
  padding: 8px 7px;
  color: #666;
  font-weight: bold;
  background: #eee;
  border-radius: 6px;
  transition: background-color 0.1s, color 0.1s;
  font-size: 14px;
  border: 1px solid var(--cell-highlight);
}

.chart-control-checkbox:hover {
  color: #000;
}

.chart-control-checkbox.active {
  background-color: var(--cell-highlight);
  color: #fff;
}

.App.dark .chart-control-checkbox {
  background: transparent;
  color: var(--cell-highlight);
}

.App.dark .chart-control-checkbox:hover {
  color: white;
  background-color: var(--cell-highlight);
}

.App.dark .chart-control-checkbox.active {
  background: #609ade;
  color: #fff;
}

.form input[type='date'],
.form input[type='text'] {
  border: 1px solid #aaa;
  border-radius: 3px;
  appearance: none !important;
  box-sizing: border-box;
  padding: 3px;
}

.form button {
  border: none;
  appearance: none !important;
  color: #000;
  border: 1px solid #84aefb;
  box-sizing: border-box;
  background: #9fc1ff;
  cursor: pointer;
  margin-left: 10px;
  height: 28px;
  border-radius: 3px;
  padding: 3px 7px;
}
.form button:hover {
  opacity: 0.9;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: loader 1.5s infinite;
  opacity: 0.3;
}
.chart-cell .loader {
  position: absolute;
  margin-left: -1.5em;
  margin-top: -1.5em;
  font-size: 0.7rem;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.warning,
.warning a {
  color: #e46b00;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 -1rem 0;
  height: 55px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid var(--text-secondary);
  background-color: white;
  z-index: 49;
  width: calc(100% - 270px);
  margin-left: 270px;
  transition: all 0.3s ease;
}
.App.full-width .nav {
  margin-left: 0;
  width: 100%;
}

.App.dark .nav {
  background: var(--background-primary);
  border-bottom: 1px solid #ffffff29;
}
.nav-right,
.nav-right div {
  display: flex;
  align-items: center;
}
.nav-right .nav-logo {
  display: none;
  height: min-content;
  margin: 0 8px 0 0;
}

.nav-logo {
  display: block;
  width: 120px;
  margin: 0 auto 24px;
}

.nav-logo img {
  width: 100%;
}

a,
a:active,
a:visited {
  color: var(--action-fill);
}

.nav-link {
  letter-spacing: 0.47px;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.125rem;
  letter-spacing: 0.47px;
  margin-right: 16px;
  transition: color 0.3s ease;
}

.nav-link.active {
  color: #444;
}

.App.dark a,
.App.dark a:active,
.App.dark a:visited {
  color: #ffffff;
}

.App.dark .nav-link {
  color: var(--text-secondary);
}

.App.dark .nav-link:active,
.App.dark .nav-link:visited {
  color: var(--text-secondary);
}

.App.dark .nav-link.active {
  color: white;
}

.App.dark .nav-link:hover {
  color: white;
}

.nav-link:hover {
  color: #444;
}

.nav-right {
  display: flex;
  align-items: center;
}
.nav-right .divider {
  margin-right: 16px;
}

.modeselect {
  width: 30px;
  height: 26px;
  background: var(--action-fill) 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff0f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.App.dark .modeselect {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.modeselect svg {
  fill: var(--text-secondary);
}

.App.dark .modeselect svg {
  fill: var(--action-fill);
}

.page-title {
  font-size: 1.8125rem;
  line-height: 2.3125rem;
}

.recharts-cartesian-axis-tick-value {
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-axis-tick-value {
  fill: white;
  font-size: 0.75rem;
  font-weight: 600;
}

.App.dark .recharts-cartesian-grid-horizontal line,
.App.dark .recharts-cartesian-grid-vertical line {
  stroke: #9b9b9b;
  stroke-dasharray: 2 2;
}

.recharts-tooltip-wrapper .recharts-default-tooltip {
  padding: 12px !important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li {
  padding: 0px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li + li {
  margin-top: 1px !important;
}

.App.dark .recharts-tooltip-wrapper .recharts-default-tooltip {
  background-color: var(--cell-stroke) !important;
  box-shadow: 0px 3px 6px #0000005c;
  border: 1px solid var(--cell-highlight) !important;
  border-radius: 4px;
}

.App-header-drawer {
  background-color: var(--background-primary);
  background: 0% 0% no-repeat padding-box;
  box-shadow: 8px 3px 6px #00000029;
  opacity: 1;
  backdrop-filter: blur(27px);
  position: fixed;
  z-index: 51;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 304px;
}

.nav-left .nav-link {
  font-weight: bold;
}

.App-header-drawer .App-header-link-container a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: var(--text-secondary);
  padding: 15px 16px;
  text-decoration: none;
  display: block;
}

.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: #303fd024;
  color: white;
}

.App-header-drawer .App-header-menu-icon {
  color: white;
  font-size: 1.3rem;
}

.App-header-drawer .App-header-link-main {
  display: flex;
  align-items: center;
}
.App-header-drawer .App-header-link-main img {
  width: 120px;
}

.App-header-links-header {
  height: 62px;
  display: flex;
  align-items: center;
  z-index: 3;
  padding-right: 1rem;
}

.App-header-menu-icon {
  color: black;
  font-size: 1.3rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 9px 10px;
}

.App.dark .App-header-menu-icon {
  color: white;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

@media all and (max-width: 1000px) {
  .nav .nav-right {
    justify-content: space-between;
    width: 100%;
  }
  .nav-right .nav-logo {
    display: inline-block;
  }
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .chart-cell {
    grid-column-start: span 2;
    grid-column-end: auto;
  }
  .chart-cell.stats {
    grid-column-start: span 1;
  }
  .App {
    width: 100%;
    margin-left: 0;
  }
  .App .nav {
    width: 100%;
    margin-left: 0;
    z-index: 51;
    padding: 0 0.5rem;
  }
  .nav-link:last-of-type {
    margin-right: 8px;
  }
  .chart-sidebar {
    position: relative;
    box-shadow: none;
    min-height: auto;
    height: auto;
    width: 100%;
    padding: 16px 0;
    grid-column: 1 / -1;
  }
  .chart-sidebar.hidden {
    transform: none;
  }
  .chart-sidebar .nav-logo,
  .chart-sidebar .sidebar-spacer {
    display: none;
  }
  .chart-sidebar-cells {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .hide-sidebar-btn {
    display: none;
  }
  .App .chart-cell.stats {
    text-align: center;
    border-bottom: 0;
    padding: 0 16px;
    border-right: 1px solid var(--cell-stroke);
  }
  .App .chart-cell.stats:last-of-type {
    border-right: 0;
  }
}

@media all and (max-width: 768px) {
  .chart-sidebar-cells {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .App .chart-cell.stats {
    border: 1px solid var(--cell-stroke);
    border-radius: 4px;
    margin-bottom: 0;
    padding: 16px;
  }
  .App .chart-cell.stats:last-of-type {
    border: 1px solid var(--cell-stroke);
  }
}

@media all and (max-width: 640px) {
  .chart-sidebar-cells {
    grid-template-columns: repeat(1, 1fr);
  }
  .nav-link:last-of-type {
    margin-right: 0px;
    padding-right: 8px;
  }
}
@media all and (max-width: 640px) {
  .chart-cell h3 {
    display: inline-block;
    position: static;
    transform: none;
    text-align: left;
  }
  .chart-header {
    justify-content: space-between;
  }
}

.content {
  margin-top: 1rem;
}

.nav-right .App-header-menu-icon-block {
  display: none;
}

.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.date-range-selector-wrapper > div {
  display: flex;
  justify-content: flex-end;
}

.react-dropdown-select-dropdown {
  position: absolute;
  top: 43px;
  right: 0px;
  left: unset !important;
  border: 1px solid #ccc;
  width: 348px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  overflow: auto;
  z-index: 9;
  max-height: 475px !important;
  width: 270px !important;
}

.App.dark .react-dropdown-select-dropdown {
  background-color: var(--action-fill) !important;
  border: 1px solid var(--action-stroke) !important;
}

.App.dark .react-dropdown-select-dropdown {
  z-index: 52;
}

.react-dropdown-select {
  border: 1px solid #e6e6f7 !important;
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 2px;
  padding: 2px 5px;
  flex-direction: row;
  direction: ltr;
  align-items: center;
  cursor: pointer;
  min-height: 36px;
  pointer-events: all;
  max-width: fit-content;
  min-width: 215px !important;
  background-color: white;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.react-dropdown-select-dropdown-handle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.App.dark .react-dropdown-select {
  border-radius: 6px;
  border: 1px solid var(--action-stroke) !important;
  background-color: var(--action-fill);
  min-width: 240px !important;
  transition: background-color 0.3s ease;
}

.App.dark .react-dropdown-select:hover {
  background-color: var(--cell-highlight) !important;
}

.App .react-dropdown-select-content {
  justify-content: center;
}

.date-range-items {
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
}

.date-range-item {
  display: flex;
  margin: 0.5rem;
  padding: 0.5rem;
  align-items: baseline;
}

.date-range-item__label {
  color: var(--text-secondary);
}

.date-range-item.selected .date-range-item__label,
.date-range-item:hover .date-range-item__label {
  color: #444;
}

.App.dark .date-range-item__label {
  color: var(--text-secondary);
}

.App.dark .date-range-item.selected .date-range-item__label,
.App.dark .date-range-item:hover .date-range-item__label {
  color: white;
}

.date-range-custom {
  color: #000;
  margin: 0.5rem;
  text-align: center;
}

.react-dropdown-select-content {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.chart-cell.mobile {
  display: none;
}

.date-range-selector-wrapper .rdrDay {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
}

.date-range-selector-wrapper .rdrMonth {
  width: 240px;
}

.date-range-selector-wrapper .rdrMonthAndYearPickers select {
  padding: 10px;
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrMonthAndYearPickers select {
  color: white;
  background: var(--action-fill);
}

.date-range-selector-wrapper .rdrNextPrevButton {
  padding: 0;
}

.date-range-selector-wrapper .rdrCalendarWrapper {
  background-color: #eee;
}

.App.dark .date-range-selector-wrapper .rdrCalendarWrapper {
  background-color: var(--action-fill);
}

.date-range-selector-wrapper .rdrWeekDay {
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrWeekDay {
  color: white;
}

.date-range-selector-wrapper .rdrDayNumber span {
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrDayNumber span {
  color: white;
}

.date-range-selector-wrapper .rdrDayToday .rdrDayNumber span:after {
  bottom: 1px;
}

.rdrDayPassive {
  opacity: 0.5;
}

.page-title-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-title-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.page-title-section h1 {
  margin: 0;
  font-weight: 600;
}
.page-title-block > h1 {
  margin-bottom: 8px;
}

.page-description {
  margin: 0;
}

@media all and (max-width: 600px) {
  .nav-right div:nth-child(2) {
    display: none;
  }
  .nav-right .App-header-menu-icon-block {
    display: block;
  }
  .page-title-section {
    display: flex;
    justify-content: unset;
    align-items: unset;
    flex-direction: column;
  }

  .page-title-section .form {
    margin-top: 1rem;
  }

  .chart-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .chart-cell {
    grid-column-start: unset !important;
    grid-column-end: unset !important;
  }

  .chart-cell.mobile {
    display: block;
  }
  .nav-link {
    margin-right: 0;
    padding-left: 16px;
  }
  .App-header-menu-icon-block {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }

  .nav-left {
    display: flex;
    align-items: center;
  }

  .nav-logo {
    display: flex;
  }

  .nav-left .nav-link {
    display: none;
  }

  .react-dropdown-select {
    max-width: 100%;
  }
}

@media (max-width: 450px) {
  .App-header-menu-icon {
    margin: 4px 5px !important;
  }
}
